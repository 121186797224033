import {
  COW_FILTERS_RESET_ALL,
  // COW_FILTERS_SET_LOCATION,
  // COW_FILTERS_SET_DISTANCE,
  COW_FILTERS_SET_MIN_PRICE,
  COW_FILTERS_SET_MAX_PRICE,
  COW_FILTERS_SET_MIN_AGE,
  COW_FILTERS_SET_MAX_AGE,
  COW_FILTERS_SET_ATTRIBUTES,
  COW_FILTERS_SET_BREEDS,
  COW_FILTERS_SET_TYPE,
  COW_FILTERS_SET_VISIBLE,
  COW_FILTERS_SET_FAVOURITED,
  COW_FILTERS_SET_INTERESTED,
  COW_FILTERS_SET_MIN_SCORE,
  COW_FILTERS_SET_MAX_SCORE,
  COW_FILTERS_SET_SEARCH
} from './constants';

export const resetAllFilters = () => ({
  type: COW_FILTERS_RESET_ALL
});
// export const setLocation = (location) => ({
//   type: COW_FILTERS_SET_LOCATION, location
// });
// export const setMaxDistance = (distance) => ({
//   type: COW_FILTERS_SET_DISTANCE, distance
// });
export const setMinPrice = (min) => ({
  type: COW_FILTERS_SET_MIN_PRICE, min
});
export const setMaxPrice = (max) => ({
  type: COW_FILTERS_SET_MAX_PRICE, max
});
export const setMinAge = (min) => ({
  type: COW_FILTERS_SET_MIN_AGE, min
});
export const setMaxAge = (max) => ({
  type: COW_FILTERS_SET_MAX_AGE, max
});
export const setAttributes = attributes => ({
  type: COW_FILTERS_SET_ATTRIBUTES, attributes
});
export const setBreeds = breeds => ({
  type: COW_FILTERS_SET_BREEDS, breeds
});
export const setType = (cowType) => ({
  type: COW_FILTERS_SET_TYPE, cowType
});
export const setSidebarVisibility = (visible) => ({
  type: COW_FILTERS_SET_VISIBLE, visible
});
export const setFavourited = isFavourited => ({
  type: COW_FILTERS_SET_FAVOURITED, isFavourited
});
export const setInterested = isInterested => ({
  type: COW_FILTERS_SET_INTERESTED, isInterested
});
export const setMinScore = score => ({
  type: COW_FILTERS_SET_MIN_SCORE, score
});
export const setMaxScore = score => ({
  type: COW_FILTERS_SET_MAX_SCORE, score
});
export const setSearch = search => ({
  type: COW_FILTERS_SET_SEARCH, search
});
