import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const SearchIcon = ({ onClick }) => (
  <svg onClick={ onClick } className="search-icon" viewBox="0 0 98.63 98.63">
    <path d="M98,91.44L70,63.53A38.84,38.84,0,1,0,63.53,70L91.44,98A4.61,4.61,0,0,0,98,98h0A4.61,4.61,0,0,0,98,91.44ZM60.67,60.67a29.9,29.9,0,1,1,0-42.29A29.9,29.9,0,0,1,60.67,60.67Z" transform="translate(-0.69 -0.69)"/>
  </svg>
);

SearchIcon.propTypes = {
  onClick: PropTypes.func
};

export default SearchIcon;
