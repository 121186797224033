import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxAndLabel from '../../util/form/components/CheckboxAndLabel';
import { getAttributes } from '../reducer';
import { setAttributes } from '../actions';
import analytics from '../../util/analytics';

const mapStateToProps = state => ({
  attributes: getAttributes(state)
});

const mapDispatchToProps = dispatch => ({
  set: attributes => dispatch(setAttributes(attributes))
});

const attributes = [
  'pedigree',
  'westCountryBeef',
  'grassFed'
];

const attributeNames = {
  'pedigree': 'Pedigree',
  'westCountryBeef': 'West Country Beef',
  'grassFed': 'Grass Fed'
};

class AttributeFilter extends Component {
  constructor (props) {
    super(props);
    this.toggleAttribute = this.toggleAttribute.bind(this);
  }
  toggleAttribute (attribute) {
    const index = this.props.attributes.indexOf(attribute);
    if (index === -1) {
      analytics('send', 'event', 'filters', 'attribute_' + attribute, 'on');
      this.props.set(this.props.attributes.concat(attribute));
    } else {
      analytics('send', 'event', 'filters', 'attribute_' + attribute, 'off');
      this.props.set(this.props.attributes.filter(item => item !== attribute));
    }
  }
  render () {
    return (
      <div id='bb-attribute-filters' className='filter-block'>
        <h3>Attributes</h3>
        { attributes.map(attribute =>
          <CheckboxAndLabel
            key={attribute}
            label={ attributeNames[attribute] }
            isChecked={this.props.attributes.includes(attribute)}
            toggle={ () => this.toggleAttribute(attribute) } />
        )}
      </div>
    );
  }
}

AttributeFilter.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  set: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeFilter);
