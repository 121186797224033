import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { getCows } from '../../reducer';
import CowListItem from './CowListItem';
import CowFilters from '../../../cowFilters/components/CowFilters';
import './cows.scss';
import { setType, setSidebarVisibility } from '../../../cowFilters/actions';
import SearchHeader from '../../../cowFilters/components/SearchHeader';
import { cowTypes as types } from '../../util/cowTypes';
import {
  getCowType,
  isSidebarVisible,
  getPageOfCows,
  countFilterMatch
} from '../../../cowFilters/reducer';
import { isFetching, getCowPage, getCowPageSize } from '../../reducer';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import Pagination from '../../../util/tables/Pagination';
import { setCowsPage } from '../../actions';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
  cattle: getPageOfCows(state),
  cattleCount: countFilterMatch(state),
  pageSize: getCowPageSize(state),
  currentPage: getCowPage(state),
  cowType: getCowType(state),
  sidebarVisible: isSidebarVisible(state),
  fetching: isFetching(state)
});

const mapDispatchToProps = dispatch => ({
  setCowType: cowType => dispatch(setType(cowType)),
  closeSidebar: () => dispatch(setSidebarVisibility(false)),
  setPage: page => dispatch(setCowsPage(page))
});

const cowTypes = {
  'young-bulls': 'YoungBulls',
  'heifers': 'Heifers',
  'cows': 'BreedingCows',
  'finished-cattle': 'FinishedCattle',
  'semen': 'Semen',
  'embryos': 'Embryos'
};

class Cows extends Component {
  constructor (props) {
    super(props);
    const type = this.props.match.params.type;
    if (cowTypes[type] !== this.props.cowType) {
      this.props.setCowType(cowTypes[type]);
    }
  }
  componentDidUpdate () {
    const type = this.props.match.params.type;
    if (cowTypes[type] !== this.props.cowType) {
      this.props.setCowType(cowTypes[type]);
    }
  }
  renderOffering () {
    if (this.props.fetching) {
      return (
        <div className="fetching-cattle">
          <LoadingIcon />
        </div>
      );
    }
    if (this.props.cattle.length > 0) {
      const rows = this.props.cattle.map(cow => (
        <CowListItem key={cow.id} cow={cow}/>
      ));
      return (
        <Fragment>
          <ul className='cattle'>
            { rows }
          </ul>
          <Pagination
            current={ this.props.currentPage || 1}
            total={ Math.ceil(this.props.cattleCount / this.props.pageSize) }
            toPage={ this.props.setPage } />
        </Fragment>
      );
    }
    const type = types[this.props.cowType];
    if (type === 'Cows for Breeding') {
      return (
        <div className="no-results">
          { `Breeding cows will be listed when spring calving is complete` }
        </div>
      );
    }
    return (
      <div className="no-results">
        { `Check back soon for more ${type} that match your filters` }
      </div>
    );
  }
  render () {
    if (!cowTypes[this.props.match.params.type]) {
      return <Redirect to='/page-not-found' />;
    }
    const blanketClass = this.props.sidebarVisible
      ? 'sidebar-blanket visible' : 'sidebar-blanket hidden';
    const canon = `${window.location.origin}/${this.props.location.pathname}`;
    const meta = `View GoodBeef Index graded cattle available now.
      See grading and authentifation documents, plus all you need to
      source high quality animals`;
    return (
      <div className='bb-page-wrapper' id='cows-list-page'>
        <Helmet>
          <title>View cattle on the GoodBeef Index</title>
          <meta name="description" content={ meta } />
          <link rel="canonical"
            href={ canon } />
        </Helmet>
        <div
          className={ blanketClass }
          onClick={ this.props.closeSidebar }>
        </div>
        <CowFilters />
        <div className='main-section'>
          <SearchHeader />
          { this.renderOffering() }
        </div>
      </div>
    );
  }
}

Cows.propTypes = {
  cattle: PropTypes.array.isRequired,
  cattleCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  setCowType: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  cowType: PropTypes.string,
  closeSidebar: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Cows);
