import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputRange from 'react-input-range';
import { getMinPrice, getMaxPrice, getPriceRange } from '../reducer';
import { setMaxPrice, setMinPrice } from '../actions';
import analytics from '../../util/analytics';

const PriceFilter = () => {
  const dispatch = useDispatch();
  const globalMinPrice = useSelector(getMinPrice);
  const setGlobalMinPrice = price => dispatch(setMinPrice(price));
  const globalMaxPrice = useSelector(getMaxPrice);
  const setGlobalMaxPrice = price => dispatch(setMaxPrice(price));
  const [ sliderMin, setSliderMin ] = useState(globalMinPrice);
  const [ sliderMax, setSliderMax ] = useState(globalMaxPrice);
  const range = useSelector(getPriceRange);
  if (sliderMin < range.min) setSliderMin(range.min);
  if (sliderMax > range.max) setSliderMax(range.max);
  useEffect(() => {
    setSliderMin(globalMinPrice);
    // console.log('globalMaxPrice', globalMaxPrice);
  }, [globalMinPrice]);
  useEffect(() => {
    setSliderMax(globalMaxPrice);
    // console.log('globalMinPrice', globalMinPrice);
  }, [globalMaxPrice]);
  const handleChangeComplete = () => {
    setGlobalMaxPrice(sliderMax);
    setGlobalMinPrice(sliderMin);
    analytics(
      'send',
      'event',
      'filters',
      'priceFilterSet',
      `${sliderMin} - ${sliderMax}`
    );
  };
  const handleSliderPricesChange = prices => {
    // console.log('price slider change', prices);
    setSliderMin(prices.min);
    setSliderMax(prices.max);
  };
  const value = {
    min: sliderMin < range.min ? range.min : sliderMin,
    max: sliderMax > range.max ? range.max : sliderMax
  };
  let rangeText = '-';
  try {
    rangeText =
      `£${sliderMin.toLocaleString()} - £${sliderMax.toLocaleString()}`;
  } catch (error) { /* */ }
  // console.log('range', range);
  const distance = range.max - range.min;
  let step = 1;
  if ((distance) > 1000) {
    step = 10;
  } else if ((distance) < 10) {
    step = 0.1;
  } else if ((distance) < 1) {
    step = 0.01;
  }
  // console.log('step', step);
  return (
    <Fragment>
      <h5>Price
        <span className="value-range">{ rangeText }</span>
      </h5>
      <InputRange
        step={ step }
        minValue={ range.min }
        maxValue={ range.max }
        value={ value }
        onChange={ handleSliderPricesChange }
        onChangeComplete={ handleChangeComplete } />
    </Fragment>
  );
};

export default PriceFilter;
