import React, { Fragment } from 'react';

/* eslint-disable max-len */
const CarcassPricingInfo = () => (
  <Fragment>
    <p className="standard-para">BeefBook uses the GoodBeef Index scoring and grading algorithm to establish a price per Kg of Carcass Weight (CW). The scoring and associated grades are:</p>
    <ul className="standard-bullet">
      <li>110+ <span className="highlight">(Supreme)</span> 100% pure bred, 100% pasture fed and a supreme eating experience</li>
      <li>100+ <span className="highlight">(Prime)</span> suckler pure-bred grass-fed beef, abundant marbling, regenerative farming</li>
      <li>90+ <span className="highlight">(Classic)</span> suckler beef, good marbling, following environmentally sensitive practices</li>
      <li>80+ <span className="highlight">(Superior)</span> cross bred suckler beef, grass fed and grazed outdoors</li>
      <li>70+ <span className="highlight">(Standard)</span> dairy cow dam, intensively reared and grain fed</li>
    </ul>
    <div className="intro-divider"></div>
    <p className="standard-para">BeefBook follows a base price plus a % uplift for each grade. Eg. a 60% uplift for the Prime grade from, say, a £3.50 per Kg Standard farmgate base price, justifies a £5.60 Classic grade price point.</p>
    <p className="standard-para">The base and % uplift parameters are at the discretion of the seller.</p>
    <p className="standard-para">The <span className="highlight">Standard farm gate base price compares with an R3 to R4H graded carcass</span> under the EUROP grading regime and abattoirs set prices according to the EUROP grid.</p>
  </Fragment>
);

export default CarcassPricingInfo;
