import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSortAttribute } from '../../cows/reducer';
import { setCowSort } from '../../cows/actions';
import DropDownSelect from '../../util/form/components/DropDownSelect';
import { sortOptions } from '../reducer';
import PropTypes from 'prop-types';
import analytics from '../../util/analytics';

const mapStateToProps = state => ({
  sortAttribute: getSortAttribute(state)
});

const mapDispatchToProps = dispatch => ({
  setSort: (sort) => dispatch(setCowSort(sort))
});

class SortFilter extends Component {
  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  getOptions () {
    return sortOptions.map(sortText => {
      const option = { name: sortText, value: sortText };
      if (this.props.sortAttribute === sortText) {
        option.selected = true;
      }
      return option;
    });
  }
  handleChange (value) {
    analytics('send', 'event', 'filters', 'sort', value);
    this.props.setSort(value);
  }
  render () {
    return (
      <div id='bb-sort-filter' className='filter-block'>
        <h3>Sort By</h3>
        <form>
          <DropDownSelect
            placeholder='select'
            testId="sort-select"
            onChange={ this.handleChange }
            options={ this.getOptions() } />
        </form>
      </div>
    );
  }
}

SortFilter.propTypes = {
  sortAttribute: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SortFilter);
