import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxAndLabel from '../../util/form/components/CheckboxAndLabel';
import {
  hasSelectedFavourite,
  hasRegisteredInterest } from '../../cows/reducer';
import { isFavourited, isInterested } from '../reducer';
import { setInterested, setFavourited } from '../actions';
import analytics from '../../util/analytics';

const mapStateToProps = state => ({
  hasFavourited: hasSelectedFavourite(state),
  hasRegisteredInterest: hasRegisteredInterest(state),
  favouritesFilterOn: isFavourited(state),
  interestFilterOn: isInterested(state)
});

const mapDispatchToProps = dispatch => ({
  interested: isInterested => dispatch(setInterested(isInterested)),
  favourited: isFavourited => dispatch(setFavourited(isFavourited))
});

class UserInteractionFilter extends Component {
  constructor (props) {
    super(props);
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.toggleInterest = this.toggleInterest.bind(this);
  }
  toggleFavourite () {
    analytics(
      'send',
      'event',
      'filters',
      'favourite',
      this.props.favouritesFilterOn ? 'off' : 'on');
    this.props.favourited(!this.props.favouritesFilterOn);
  }
  toggleInterest () {
    analytics(
      'send',
      'event',
      'filters',
      'interest',
      this.props.favouritesFilterOn ? 'off' : 'on');
    this.props.interested(!this.props.interestFilterOn);
  }
  render () {
    let hide = true;
    let favouriteFilter = null;
    let interestFilter = null;
    if (this.props.hasFavourited) {
      hide = false;
      favouriteFilter = (
        <CheckboxAndLabel
          label={ 'Favourites' }
          isChecked={this.props.favouritesFilterOn }
          toggle={ this.toggleFavourite } />
      );
    }
    if (this.props.hasRegisteredInterest) {
      hide = false;
      interestFilter = (
        <CheckboxAndLabel
          label={ 'Purchase Inquiry' }
          isChecked={this.props.interestFilterOn }
          toggle={ this.toggleInterest } />
      );
    }
    if (hide) return null;
    return (
      <div id='bb-interaction-filters'
        data-cy="user-activity-filter"
        className='filter-block'>
        <h3>Activity</h3>
        { favouriteFilter }
        { interestFilter }
      </div>
    );
  }
}

UserInteractionFilter.propTypes = {
  hasFavourited: PropTypes.bool.isRequired,
  hasRegisteredInterest: PropTypes.bool.isRequired,
  favouritesFilterOn: PropTypes.bool.isRequired,
  interestFilterOn: PropTypes.bool.isRequired,
  interested: PropTypes.func.isRequired,
  favourited: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(UserInteractionFilter);
