import React from 'react';
import PropTypes from 'prop-types';
import ViewIcon from '../../icons/components/ViewIcon';

const Button = ({ label, onClick, className }) => (
  <div
    onClick={onClick}
    className={ className ? 'bb-button ' + className : 'bb-button'}>
    <ViewIcon />
    <span className="text">{ label }</span>
  </div>
);

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Button;
