import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '../../util/icons/components/SearchIcon';
import { setSearch } from '../actions';
import { getSearchFilter } from '../reducer';
import './search-filter.scss';

const SearchFilter = () => {
  const searchText = useSelector(getSearchFilter);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const handleChange = () => {
    const inputValue = inputRef.current.value;
    dispatch(setSearch(inputValue));
  };
  return (
    <div className="filter-block" id="listing-search-filter">
      <h3>Search</h3>
      <div className="search-box-wrapper">
        <input ref={ inputRef }
          placeholder="Search by tag or name"
          onChange={ handleChange }
          data-cy="search-listings-input"
          value={ searchText || '' } />
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchFilter;
