import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputRange from 'react-input-range';
import { getScoreMin, getScoreMax, getScoreRange } from '../reducer';
import { setMaxScore, setMinScore } from '../actions';
import analytics from '../../util/analytics';

const ScoreFilter = () => {
  const dispatch = useDispatch();
  const globalMinScore = useSelector(getScoreMin);
  const setGlobalMinScore = score => dispatch(setMinScore(score));
  const globalMaxScore = useSelector(getScoreMax);
  const setGlobalMaxScore = score => dispatch(setMaxScore(score));
  const [ sliderMin, setSliderMin ] = useState(globalMinScore);
  const [ sliderMax, setSliderMax ] = useState(globalMaxScore);
  const range = useSelector(getScoreRange);
  if (sliderMin < range.min) setSliderMin(range.min);
  if (sliderMax > range.max) setSliderMax(range.max);
  useEffect(() => {
    setSliderMin(globalMinScore);
    // console.log('globalMaxScore', globalMaxScore);
  }, [globalMinScore]);
  useEffect(() => {
    setSliderMax(globalMaxScore);
    // console.log('globalMinScore', globalMinScore);
  }, [globalMaxScore]);
  const handleChangeComplete = () => {
    setGlobalMaxScore(sliderMax);
    setGlobalMinScore(sliderMin);
    analytics(
      'send',
      'event',
      'filters',
      'scoreFilterSet',
      `${sliderMin} - ${sliderMax}`
    );
  };
  const handleSliderScoresChange = scores => {
    setSliderMin(scores.min);
    setSliderMax(scores.max);
  };
  const value = {
    min: sliderMin < range.min ? range.min : sliderMin,
    max: sliderMax > range.max ? range.max : sliderMax
  };
  return (
    <Fragment>
      <h5>GoodBeef Index
        <span className="value-range">
          { `${sliderMin} - ${sliderMax}` }
        </span>
      </h5>
      <InputRange
        key={ JSON.stringify(range) }
        minValue={ range.min }
        maxValue={ range.max }
        value={ value }
        onChange={ handleSliderScoresChange }
        onChangeComplete={ handleChangeComplete } />
    </Fragment>
  );
};

export default ScoreFilter;
