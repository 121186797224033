import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxAndLabel from '../../util/form/components/CheckboxAndLabel';
import DropDownArrowIcon from '../../util/icons/components/DropDownArrowIcon';
import { getBreeds } from '../reducer';
import { setBreeds } from '../actions';
import analytics from '../../util/analytics';

const mapStateToProps = state => ({
  breeds: getBreeds(state)
});

const mapDispatchToProps = dispatch => ({
  set: breeds => dispatch(setBreeds(breeds))
});

export const breeds = [
  'Aberdeen Angus',
  'Stabiliser',
  'Red Ruby Devon',
  'South Devon',
  'Hereford',
  'Wagyu',
  'Beef Shorthorn',
  'Highland',
  'Blue Grey',
  'Dexter',
  'Welsh Black',
  'Red Poll',
  'Lincoln Red',
  'Galloway',
  'Belted Galloway',
  'Sussex'
];

class BreedFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.toggleBreed = this.toggleBreed.bind(this);
  }
  toggleBreed (breed) {
    const index = this.props.breeds.indexOf(breed);
    if (index === -1) {
      analytics('send', 'event', 'filters', 'breed_' + breed, 'on');
      this.props.set(this.props.breeds.concat(breed));
    } else {
      analytics('send', 'event', 'filters', 'breed_' + breed, 'off');
      this.props.set(this.props.breeds.filter(item => item !== breed));
    }
  }
  render () {
    const list = this.state.expanded ? breeds.slice() : breeds.slice(0, 6);
    list.sort();
    const expandContract = this.state.expanded ? (
      <div className="contract"
        onClick={ () => this.setState({ expanded: false })}>
        <span className="text">contract</span>
        <DropDownArrowIcon />
      </div>
    ) : (
      <div className="expand"
        onClick={ () => this.setState({ expanded: true })}>
        <span className="text">expand...</span>
        <DropDownArrowIcon />
      </div>
    );
    return (
      <div id='bb-breed-filters' className='filter-block'>
        <h3>Breeds</h3>
        { list.map(breed =>
          <CheckboxAndLabel
            key={breed}
            label={breed}
            isChecked={this.props.breeds.includes(breed)}
            toggle={ () => this.toggleBreed(breed) } />
        )}
        { expandContract }
      </div>
    );
  }
}

BreedFilter.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.string).isRequired,
  expanded: PropTypes.bool,
  set: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BreedFilter);
