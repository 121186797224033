import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputRange from 'react-input-range';
import { getMinAge, getMaxAge, getAgeRange, getCowType } from '../reducer';
import { setMaxAge, setMinAge } from '../actions';
import analytics from '../../util/analytics';
import { getAgeStringFromMonths } from '../../util/datesAndAges';

const AgeFilter = () => {
  const dispatch = useDispatch();
  const cowType = useSelector(getCowType);
  const globalMinAge = useSelector(getMinAge);
  const setGlobalMinAge = age => dispatch(setMinAge(age));
  const globalMaxAge = useSelector(getMaxAge);
  const setGlobalMaxAge = age => dispatch(setMaxAge(age));
  const [ sliderMin, setSliderMin ] = useState(globalMinAge);
  const [ sliderMax, setSliderMax ] = useState(globalMaxAge);
  const range = useSelector(getAgeRange);
  if (sliderMin < range.min) setSliderMin(range.min);
  if (sliderMax > range.max) setSliderMax(range.max);
  useEffect(() => {
    setSliderMin(globalMinAge);
    // console.log('globalMaxAge', globalMaxAge);
  }, [globalMinAge]);
  useEffect(() => {
    setSliderMax(globalMaxAge);
    // console.log('globalMinAge', globalMinAge);
  }, [globalMaxAge]);
  const handleChangeComplete = () => {
    setGlobalMaxAge(sliderMax);
    setGlobalMinAge(sliderMin);
    analytics(
      'send',
      'event',
      'filters',
      'ageFilterSet',
      `${sliderMin} - ${sliderMax}`
    );
  };
  const handleSliderAgesChange = ages => {
    setSliderMin(ages.min);
    setSliderMax(ages.max);
  };
  const value = {
    min: sliderMin < range.min ? range.min : sliderMin,
    max: sliderMax > range.max ? range.max : sliderMax
  };
  let rangeText = '-';
  try {
    const min = cowType === 'FinishedCattle' ?
      sliderMin + ' months' : getAgeStringFromMonths(sliderMin);
    const max = cowType === 'FinishedCattle' ?
      sliderMax + ' months' : getAgeStringFromMonths(sliderMax);
    rangeText = `${min} - ${max}`;
  } catch (error) { /* */ }
  return (
    <Fragment>
      <h5>Age
        <span className="value-range">{ rangeText }</span>
      </h5>
      <InputRange
        key={ JSON.stringify(range) }
        minValue={ range.min }
        maxValue={ range.max }
        value={ value }
        onChange={ handleSliderAgesChange }
        onChangeComplete={ handleChangeComplete } />
    </Fragment>
  );
};

export default AgeFilter;
