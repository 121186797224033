import React, { Fragment } from 'react';
import GBIText from '../components/GBIText';

/* eslint-disable max-len */
const CowsPricingInfo = () => (
  <Fragment>
    <p className="standard-para"><GBIText /> combines the Breedplan TI and SRI rankings to indicate where the cow ranks in the breed pool. BeefBook then associates the combined ranking with the GoodBeef Index grade structure for establishing price points:</p>
    <ul className="standard-bullet">
      <li>Top 1% <span className="highlight">(Supreme)</span> exceptional breeding cow across the spectrum of traits for the breed</li>
      <li>Top 5% <span className="highlight">(Prime)</span> high-class breeding cow with strengths across the range of genetic traits</li>
      <li>Top 10% <span className="highlight">(Classic)</span> is a well-rounded breeding cow with some outstanding genetic traits</li>
      <li>Top 35% <span className="highlight">(Superior)</span> is a well-balanced breeding cow, with good fertility and easy birthing</li>
      <li>Top 50% <span className="highlight">(Standard)</span> the average for the breed as a whole</li>
    </ul>
    <div className="intro-divider"></div>
    <p className="standard-para">BeefBook follows a base price plus a % uplift for each grade. Eg. a 50% uplift for the Classic grade from, say, a £2200 Standard base price, justifies a £3300 Classic grade price point. The example here is for a dry, in-calf cow.</p>
    <p className="standard-para">The base and % uplift parameters are going to vary by breed and are at the discretion of the seller. What the Beef Book is seeking to promote is a fair price for a given level of quality.</p>
  </Fragment>
);

export default CowsPricingInfo;
